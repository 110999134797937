<template>
  <div class="container">
    <users/>
  </div>
</template>

<script>
import Users from "@/views/users";
export default {
  name: "home",
  components: {Users},

}
</script>

<style scoped>

</style>