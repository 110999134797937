<template>
  <v-container>
  <v-card v-if="desserts.length > 0">
    <v-card-title>
      Nutrition
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          @input="setValue"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
  </v-card>
  </v-container>
</template>

<script>
export default {
  name: "user-search",
  data(){
    return{
      search: ''
    }
  },
  computed:{
    desserts: {
      set(value) {
        this.$store.dispatch("setData", value)
      },

      get() {
        return this.$store.getters.getData
      }
    },
  },

  methods:{
    setValue(){
      this.$emit('getValue', this.search)
    }
  }
}
</script>

<style scoped>

</style>