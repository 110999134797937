import axios from "axios";

const state = {
    userData: [],
    editedUserData: {
        name: '',
        age: '',
        email: '',
        password: '',
        confirmPassword: '',
        country: ''
    },
    index: -1,
    change:0,
    loader: false,
    deleteIndex: 0
};

const getters = {
    getData(state) {
        return state.userData
    },

    getChanges(state){
        return state.change
    },

    getDeleteIndex() {
        return state.deleteIndex
    },

    getEdit(state) {
        return state.editedUserData
    },

    getIndex(state) {
        return state.index
    },

    getLoader(state) {
        return state.loader
    }
};

const mutations = {};

const actions = {

    setData(context, data) {
        context.state.userData = data
    },

    changeState(context, index) {
        context.state.index = index
    },

    indexDelete(context, index) {
        context.state.deleteIndex = index
    },

    changeEdit(context, editedUser) {
        context.state.editedUserData = editedUser
    },

    async getUserData(context) {
        context.state.loader = true;
       await axios.get("https://list-e86ef-default-rtdb.firebaseio.com/users.json")
            .then(res => context.state.userData = res.data)
            .catch(res => console.log(res.error));
        context.state.loader = false;
    },

    async getData(){
        console.log('kkkkkkkkk')
        await axios.get("https://jsonplaceholder.typicode.com/todos")
            .then(res => console.log(res))
            .catch(res => console.log(res.error));
    },

    async putUserData(context, index) {
        context.state.loader = true;
        await axios.put(`https://list-e86ef-default-rtdb.firebaseio.com/users/${encodeURIComponent(index)}.json`, context.state.editedUserData)
            .then(res => context.state.userData[index] = res.data)
            .catch(res => console.log(res.error))
        context.state.change++
        context.state.loader = false;
    },


    async deleteUser(context, index) {
        context.state.loader = true;
        await axios.delete(`https://list-e86ef-default-rtdb.firebaseio.com/users/${encodeURIComponent(index)}.json`);
        context.state.userData.splice(index, 1)
        context.state.change++
        context.state.loader = false;
    }

};

export default {
    state,
    getters,
    mutations,
    actions
}