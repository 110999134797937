import Vue from 'vue'
import VueRouter from 'vue-router'
import Users from '../views/users'
import UsersTable from "../views/users-table";
import Home from "../views/home";
import userSearch from "@/views/user-search";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    redirect:'users/users-table',
    children:[
      {
        path:'users-table',
        component:UsersTable
      },

      {
        path:'user-search',
        component:userSearch
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
