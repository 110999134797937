<template>
  <v-container>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Form</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.age"
                    label="Age"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.password"
                    label="Password"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.confirmPassword"
                    label="ConfirmPassword"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.country"
                    label="Country"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog
        v-model="deleteDialog"
        max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this user?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="deleteDialog = false"
          >
            Disagree
          </v-btn>

          <v-btn
              color="green darken-1"
              text
              @click="deleteUser"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-skeleton-loader
        height="100vh"
        class="mx-auto"
        type="table"
        v-if="desserts.length === 0"
    ></v-skeleton-loader>

    <v-expand-x-transition>
      <v-data-table
          v-if="desserts.length > 0"
          :headers="headers"
          :items="desserts"
          class="elevation-3"
          :search="search"
      >

        <template v-slot:item.edit="{ item }">
          <v-icon
              small
              color="green"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>

        <template  v-slot:item.delete="{ item }">
          <v-icon
              small
              color="red"
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:item.number="{ item }">
          {{ desserts.indexOf(item) + 1 }}
        </template>

      </v-data-table>
    </v-expand-x-transition>

  </v-container>
</template>

<script>
export default {
  props:["search"],
  name: "users-table",
  data() {
    return {
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        {
          text: 'Name',
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Email',
          align: 'center',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Age',
          align: 'center',
          sortable: false,
          value: 'age',
        },
        {
          text: 'Password',
          align: 'center',
          sortable: false,
          value: 'password',
        },
        {
          text: 'ConfirmPassword',
          align: 'center',
          sortable: false,
          value: 'confirmPassword',
        },
        {
          text: 'Country',
          align: 'center',
          sortable: false,
          value: 'country',
        },
        {
          text: 'Edit',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'Delete',
          align: 'end',
          sortable: false,
          value: 'delete'
        },
      ],
      editedItem: {
        name: '',
        age: '',
        email: '',
        password: '',
        confirmPassword: '',
        country: ''
      },
      dialog: false,
      deleteDialog:false,
      deleteFunction:false,
      editedIndex: 0,
      connection: null,
    }
  },

  methods: {
    editItem(item) {
      this.index = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item);
      this.edit = Object.assign({}, item);
      this.dialog = true
    },

    deleteItem(item) {
      this.deleteIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item);
      this.deleteDialog = true
    },

    deleteUser(){
      this.deleteDialog = false
      this.desserts.splice(this.deleteIndex, 1)
      this.$store.dispatch('deleteUser' , this.deleteIndex);
    },

     close() {
      this.dialog = false;
    },

    save() {
      if (this.index > -1) {
        this.edit = this.editedItem;
        this.$store.dispatch('putUserData', this.index)
      } else {
        this.desserts.push(this.edit)
      }
      this.close()
    }
  },

  computed: {
    desserts: {
      set(value) {
        this.$store.dispatch("setData", value)
      },

      get() {
        return this.$store.getters.getData
      }
    },

    edit: {
      set(value) {
        this.$store.dispatch("changeEdit", value)
      },

      get() {
        return this.$store.getters.getEdit
      }
    },

    index: {
      set(value) {
        this.$store.dispatch("changeState", value);
      },
      get() {
        return this.$store.getters.getIndex;
      },
    },

    deleteIndex: {
      set(value) {
        this.$store.dispatch("indexDelete", value);
      },
      get() {
        return this.$store.getters.getDeleteIndex;
      },
    },

    loader() {
      return this.$store.getters.getLoader
    }
  },

  created() {
    this.$store.dispatch('getUserData');
    this.$store.dispatch('getData');
  }
}
</script>

<style scoped>

</style>