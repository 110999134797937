<template>
  <v-app>
    <v-main>
      <loader v-if="loader === true" />
      <router-view>
      </router-view>
    </v-main>
  </v-app>
</template>
<script>

import Loader from "@/components/loader";
export default {
  name: 'App',
  components: {Loader},
  data: () => ({
    //
  }),

  computed:{
    loader(){
      return this.$store.getters.getLoader
    }
  },
};
</script>
