<template>
  <div class="loading">
    <div class="logoWrapper">
      <div class="content">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader"
}
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: relative;

  .logoWrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    gap: 10px;

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .lading-text {
        font-size: clamp(14px, 2vw, 18px);
        color: #c2a748;
        font-family: IBMPlexSans, serif;
        margin-left: 7%;
      }
    }
  }
}
</style>