<template>
  <v-container>
    <user-search @getValue="getValue"/>
    <users-table :search="getValueSearch" :key="change"/>
  </v-container>
</template>

<script>


import UserSearch from "@/views/user-search";
import UsersTable from "@/views/users-table";

export default {
  name: "users",
  data(){
    return{
      getValueSearch:''
    }
  },
  components: {UsersTable, UserSearch},
  methods:{
    getValue(value){
      this.getValueSearch = value
    }
  },
  computed:{
    change(){
      return this.$store.getters.getChanges
    }
  }
}
</script>

<style scoped>

</style>